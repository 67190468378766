import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Observable, of, throwError, catchError, switchMap, map } from 'rxjs';
import { LoaderEnabled, SkipPreloader } from './loader.service';
import { BusinessProcessApiService } from '../modules/business-process/business-process-api.service';
import { IBp, IForm } from '../modules/business-process/business-process.types';
import { showErrorAlert } from '../../shared/components/alerts/alert.decorator';

export interface BusinessProcessIds {
  pid: string;
  tid: string;
}

export interface ProcessIdsModel {
  pid: string | null;
  tid: string | null;
}

interface StartBpOptions {
  skipLocationChange?: boolean;
}

@Injectable({
  providedIn: 'root',
})

export class BpService {
  constructor(
    private bpApiService: BusinessProcessApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  start(processName: string, data: any, options: StartBpOptions = {}, skipLoader = SkipPreloader.false): Observable<IBp> {
    return this.bpApiService.startProcess(processName, data)
      .pipe(
        switchMap(businessProcess => (options?.skipLocationChange
          ? of(businessProcess)
          : this.saveProcessIds(businessProcess))),
      );
  }

  @LoaderEnabled()
  startBpAndGetData<T extends IForm = IForm>(processName: string, data: any, options: StartBpOptions = {}, skipLoader = SkipPreloader.false): Observable<T> {
    return this.start(processName, data, options, skipLoader)
      .pipe(
        switchMap(businessProcess => this.bpApiService.getFormData<T>(businessProcess.pid, businessProcess.taskId, skipLoader)),
      );
  }

  @LoaderEnabled()
  getCurrentBpFormData<T extends IForm = IForm>(): Observable<T> {
    const { pid, tid } = this.getProcessIds();
    return this.bpApiService.getFormData(pid, tid);
  }

  observeProcessIds():Observable<ProcessIdsModel> {
    return this.route.queryParams.pipe(
      map(params => ({
        pid: params.pid ? String(params.pid) : null,
        tid: params.tid ? String(params.tid) : null,
      })),
    );
  }

  public getProcessIds(): BusinessProcessIds {
    return {
      pid: this.route.snapshot.queryParams.pid,
      tid: this.route.snapshot.queryParams.tid,
    };
  }

  // TODO: maybe use localStorage as store? Pros: don't have async problems like url store
  saveProcessIds(businessProcess: IBp, url?: string): Observable<IBp> {
    return from(this.router.navigate(
      url ? [url] : [],
      {
        queryParams: {
          pid: businessProcess.pid, tid: businessProcess.taskId,
        },
        replaceUrl: true,
        queryParamsHandling: 'merge',
      },
    ))
      .pipe(switchMap(() => of(businessProcess)));
  }

  updateProcessTid(tid: string | undefined): Observable<boolean> {
    if (!tid) {
      return of(false);
    }
    return from(this.router.navigate([], {
      queryParams: {
        tid,
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
    }));
  }

  submit(pid: string, tid: string, data: any): Observable<IBp> {
    return this.bpApiService.submitProcess(pid, tid, data)
      .pipe(
        switchMap(businessProcess => this.saveProcessIds(businessProcess)),
        catchError((error: any) => this.updateProcessTid(error.error?.requested?.tid)
          .pipe(switchMap(() => throwError(error)))),
      );
  }

  @LoaderEnabled()
  submitCurrentBp(data: any): Observable<IBp> {
    const { pid, tid } = this.getProcessIds();
    return this.submit(pid, tid, data);
  }

  @showErrorAlert()
  submitAndGetForm<T extends IForm = IForm>(data: any): Observable<T> {
    const { pid, tid }: BusinessProcessIds = this.getProcessIds();
    return this.submit(pid, tid, data)
      .pipe(
        switchMap(businessProcess => this.bpApiService.getFormData<T>(businessProcess.pid, businessProcess.taskId)),
      );
  }

  stepBack(currentFormKey: string, prevFormKey: string): Observable<any> {
    const { pid, tid }: BusinessProcessIds = this.getProcessIds();
    return this.bpApiService.stepBackProcess(pid, tid, currentFormKey, prevFormKey);
  }

  sendMessage(pid: string, tid: string, message: string, data: any = {}): Observable<IBp> {
    return this.bpApiService.sendMessage(pid, tid, message, data)
      .pipe(
        switchMap(businessProcess => this.saveProcessIds(businessProcess)),
      );
  }

  @LoaderEnabled()
  sendMessageCurrentBp(message: string, data: any = {}): Observable<IBp> {
    return this.sendMessage(this.getProcessIds().pid, this.getProcessIds().tid, message, data);
  }

  @LoaderEnabled()
  goToStepAndGetForm<T extends IForm = IForm>(message: string, navigateTo?: string, data?: any): Observable<T> {
    return this.sendMessageCurrentBp(message, data)
      .pipe(
        switchMap(businessProcess => this.saveProcessIds(businessProcess, navigateTo)),
        switchMap(businessProcess => this.bpApiService.getFormData<T>(businessProcess.pid, businessProcess.taskId)),
      );
  }
}
