import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild, ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService } from '../../../core/services/utils.service';

interface CallbackFunction {
  (searchText: string): Observable<unknown>;
}

@Component({
  selector: 'tax-search-dropdown-list',
  templateUrl: './search-dropdown-list.component.html',
  styleUrls: ['./search-dropdown-list.component.sass'],
})

export class SearchDropDownListComponent implements OnChanges, OnInit {
  @Input() data: any;
  @Input() placeholder = '';
  @Input() currentItem: any;
  // @ts-ignore
  @Input() callback: CallbackFunction;
  @Input() disabled = false;
  @Input() manualInput = false;
  @Input() isInfinite = false;
  @Input() scrollable = false;
  @Input() manualInputText = '';
  @Input() style = '';
  @Input() helpText = '';

  @Output() submittedData = new EventEmitter<any>();
  @Output() selectManualInput = new EventEmitter<void>();
  @Output() cleanEvent = new EventEmitter<void>();

  // @ts-ignore
  @ViewChild('search') searchElement: ElementRef;

  // @ts-ignore
  @HostListener('document:click', ['$event']) closeSelect(event): void {
    // @ts-ignore
    const isClickOnComponent: boolean = event.composedPath().some(element => element === this.viewContainerRef.element.nativeElement);
    if (!isClickOnComponent) {
      this.isDropdownListVisible = false;
      this.searchText = '';
    }
  }

  isDropdownListVisible: boolean = false;
  buttonText: string = '';
  searchText: string = '';

  constructor(
    public utilService: UtilsService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.buttonText = this.currentItem || '';
  }

  ngOnChanges(): void {
    if (this.disabled) {
      this.isDropdownListVisible = false;
      this.buttonText = '';
    }
    this.buttonText = this.currentItem;
  }

  updateVisibility(): void {
    if (this.manualInput) return;
    if (!this.disabled) {
      this.isDropdownListVisible = !this.isDropdownListVisible;
    }
    if (this.isDropdownListVisible) {
      this.updateData();
    }
    this.searchText = '';
  }

  private updateData(): void {
    if (!this.callback) {
      return;
    }

    this.buttonText = '';
    this.callback(this.searchText)
      .subscribe((response) => {
        this.data = response;
      });
  }

  selectItem(item: any): void {
    if (item.child && item.child.length > 0) {
      return;
    }
    this.isDropdownListVisible = !this.isDropdownListVisible;
    this.buttonText = item.title;
    this.searchText = '';
    this.submittedData.emit(item);
  }
}
